import crumbs from 'crumbsjs';

const cookieNoticer = document.querySelector('#cookie_consent');
const declinedCookiesBtn = document.querySelector('#declineCookies');
const acceptedCookiesBtn = document.querySelector('#acceptCookies');
const cookieNoticerCk = crumbs.get('acceptedCookies');
const cookieNoticerLs = crumbs.ls.get('acceptedCookies');
const gtmId = document.head.querySelector("[name=gtm]") ? document.head.querySelector("[name=gtm]").content : '';
const gaId = document.head.querySelector("[name=ga]") ? document.head.querySelector("[name=ga]").content : '';

window.addEventListener('load', (e) => {
  initGa();
  if (document.body.contains(cookieNoticer)) {
    if (checkCookies('cookie') === false) {
      if (checkCookies('localStorage') === false) {
        cookieNoticer.style.transform = 'translateY(0)';
      } else {
        cookieNoticer.style.transform = 'translateY(0)';
      }
    }
  }
});

if (document.body.contains(cookieNoticer)) {
  if (document.body.contains(declinedCookiesBtn)) {
    declinedCookiesBtn.addEventListener('click', (e) => {
      setCookie('all', true);
      deleteCookieNotice(true);
    }, false);
  }
  acceptedCookiesBtn.addEventListener('click', (e) => {
    initGtm();
    setCookie('all', true);
    deleteCookieNotice(true);
  }, false);
}

function checkCookies(type) {
  let hasCookie = false;

  if (type === 'cookie') {
    if (cookieNoticerCk === 'declined') {
      hasCookie = true;
      deleteCookieNotice(false);
    } else if (cookieNoticerCk === 'accepted') {
      initGtm();
      deleteCookieNotice(false);
      hasCookie = true;
    }
  } else if (type === 'localStorage') {
    if (cookieNoticerLs === 'declined') {
      hasCookie = true;
      deleteCookieNotice(false);
    } else if (cookieNoticerLs === 'accepted') {
      initGtm();
      deleteCookieNotice(false);
      hasCookie = true;
    }
  }
  return hasCookie;
}

function setCookie(type, accepted) {
  let validation = accepted ? 'accepted' : 'declined';
  return (type === 'localStorage') ? crumbs.ls.set("acceptedCookies", validation, {
    type: "day",
    value: 7
  }, "/crumbsjs") : (type === 'all') ? crumbs.ls.set("acceptedCookies", validation, {
    type: "day",
    value: 7
  }, "/crumbsjs") && crumbs.set("acceptedCookies", validation, {
    type: "day",
    value: 7
  }, "/crumbsjs") : crumbs.set("acceptedCookies", validation, {
    type: "day",
    value: 7
  }, "/crumbsjs");
}

function deleteCookieNotice(wasVisible) {
  if (wasVisible) {
    cookieNoticer.classList.add('cookieconsent__close');
    setTimeout(() => {
      return cookieNoticer.remove();
    }, 15000)
  } else {
    return cookieNoticer.remove();
  }
}

function initGtm() {
  if (window.gtmDidInit) return false;
  if (gtmId === '' || gtmId === null || gtmId === undefined) return false;
  // removeGa();
  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js'
    });
    var f = d.getElementsByTagName('meta')[0],
      j = d.createElement(s),
      dl = l != 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.id = 'gtm-init'
    j.src =
      'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    f.parentNode.insertBefore(j, f);
    j.onload = async () => {
      window.gtmDidInit = true;
      startGtm();
    }
  })(window, document, 'script', 'dataLayer', gtmId);
}

function initGa() {
  if (gaId === '' || gaId === null || gaId === undefined) return false;
  const ga = document.createElement('script');
  ga.type = 'text/javascript';
  ga.id = 'ga-init';
  ga.async = true;
  ga.src = 'https://www.googletagmanager.com/gtag/js?id=' + gaId;
  if (isMyScriptLoaded(ga.src)) return false;

  const s = document.getElementsByTagName('meta')[0];
  s.parentNode.insertBefore(ga, s);

  ga.onload = () => {
    sendPageView();
  };
}

function startGtm() {
  dataLayer.push({
    event: 'gtm.js',
    'gtm.start': (new Date()).getTime(),
    'gtm.uniqueEventId': 0
  });
}

function sendPageView() {
  window.dataLayer = window.dataLayer || [];

  function gtag() {
    dataLayer.push(arguments);
  }
  gtag('js', new Date());
  gtag('config', gaId);
}

function removeGa() {
  const scriptGa2 = document.querySelector('script[src="https://www.google-analytics.com/analytics.js"]');
  const scriptGa = document.getElementById('ga-init');
  scriptGa.remove();
  if (scriptGa2 !== '' && scriptGa2 !== null && scriptGa2 !== undefined) {
    scriptGa2.remove();
  }
}

function isMyScriptLoaded(url) {
  var scripts = document.getElementsByTagName('script');
  for (var i = scripts.length; i--;) {
    if (scripts[i].src == url) return true;
  }
  return false;
}