import { gsap } from 'gsap'
import { getWidth } from './helpers'

if (getWidth() < 1270) {
  /**
   * HTML
   */
  const navBtn = document.querySelector('.nav__hamburger');
  const menuCon = document.querySelector('.navigation__bar');
  const body = document.querySelector('body');
  const headerUsps = document.querySelector('.header__usps');
  const headerContent = document.querySelector('.header__content')

  /**
   * GSAP
   */
  const DURATION = .2;
  const GSAP_ELEMENT_1 = '.nav__menu__item__parent';
  var CURRENT_ANIMATE;

  let tl = new gsap.timeline();
  tl.add("stagger", "+=0.2");
  tl.staggerFrom(
    GSAP_ELEMENT_1, 
    DURATION, 
    { opacity: 0, x: -50 }, 
    .1, 
    "stagger"
  ).reverse();
  window.menuAnimation = tl;

  navBtn.addEventListener( 'click', (e) => {
    toggleNavigation();
  });

  function toggleNavigation() {
    if (headerUsps.style.zIndex === '-1') {
      headerUsps.style.zIndex = '2';
    } else {
      headerUsps.style.zIndex = '-1';
    }

    if (headerContent.style.zIndex === '-1') {
      headerContent.style.zIndex = '2';
    } else {
      headerContent.style.zIndex = '-1';
    }

    if (navBtn.classList.contains('active')) {
      navBtn.classList.remove('active');
    } else {
      navBtn.classList.add('active');
    }

    if (menuCon.classList.contains('active')) {
      menuCon.classList.remove('active')
    } else {
      menuCon.classList.add('active')
    }

    if (body.classList.contains('modal-open')) {
      body.classList.remove('modal-open');
    } else {
      body.classList.add('modal-open');
    }

    if (CURRENT_ANIMATE) {
      window.menuAnimation.reversed(!window.menuAnimation.reversed());
      if (CURRENT_ANIMATE != window.menuAnimation && CURRENT_ANIMATE.reversed() == false) {
        CURRENT_ANIMATE.reversed(!CURRENT_ANIMATE.reversed());
      }
    } else {
      window.menuAnimation.reversed(!window.menuAnimation.reversed())
    }
    CURRENT_ANIMATE = window.menuAnimation;
  }

} else {
  document.addEventListener( 'mouseover', (e) => {

    // Open dropdown
    if (e.target.matches('li.nav__menu__item.menu-item-has-children a.parent_link')) {
      const dropdowns = document.querySelectorAll('.navigation__dropdown');
      [].forEach.call(dropdowns, (element) => { 
        // element.dataset.index

        // Show active dropdown
        if (!e.target.parentNode.lastChild.classList.contains('navigation__dropdown__visible')) e.target.parentNode.lastChild.classList.add('navigation__dropdown__visible');
        
        // Active other dropdowns if not already active: because of sliding animation
        if (!element.classList.contains('navigation__dropdown__visible')) element.classList.add('navigation__dropdown__visible');

        // Make current dropdown active
        if (e.target.parentNode.lastChild.classList.contains('navigation__dropdown__inactive')) e.target.parentNode.lastChild.classList.remove('navigation__dropdown__inactive');
        
      
      });
    }

    if (!e.target.matches('li.menu-item-has-children a') &&
      !e.target.matches('.navigation__dropdown__wrapper') &&
      !e.target.matches('.navigation__items__dropdown') &&
      !e.target.matches('.navigation__items') &&
      !e.target.matches('.navigation__items.navigation__items__dropdown li') &&
      !e.target.matches('.navigation__items.navigation__items__dropdown li a') &&
      !e.target.matches('.parent-link') && 
      !e.target.matches('.navigation__dropdown.navigation__dropdown__visible') &&
      !e.target.matches('.navigation__dropdown__visible') &&
      !e.target.matches('.header__usps') &&
      !e.target.matches('.services__card__navwrapper') &&
      !e.target.matches('.services__card__goto') &&
      !e.target.matches('.services__card__navwrapper img') &&
      !e.target.matches('.services__card__navwrapper__span') &&
      !e.target.matches('.services__card__navwrapper__goto') &&
      !e.target.matches('.services__card__navwrapper__image')
    ) {

      const dropdowns = document.querySelectorAll('.navigation__dropdown.navigation__dropdown__visible');
      [].forEach.call(dropdowns, function (element) { 
        element.classList.remove('navigation__dropdown__visible');
        element.classList.remove('navigation__dropdown__inactive');
      });
    
    }

  });
}